import React from "react";
import getExternalLinkProps from "util/getExternalLinkProps";
import StyledButton from "./StyledButton";
import { variants, sizes } from "./types";

const Button = ({ ...props }) => {
	const { startIcon, endIcon, children, internal, isLoading, disabled } = props;
	const internalProps = internal === "false" ? getExternalLinkProps() : {};
	const isDisabled = isLoading || disabled;

	return (
		<StyledButton {...internalProps} {...props} isLoading={isLoading} disabled={isDisabled}>
			{React.isValidElement(startIcon) &&
				React.cloneElement(startIcon, {
					mr: "0.5rem",
				})}
			{children}
			{React.isValidElement(endIcon) &&
				React.cloneElement(endIcon, {
					ml: "0.5rem",
				})}
		</StyledButton>
	);
};

Button.defaultProps = {
	variant: variants.PRIMARY,
	size: sizes.MD,
	external: "false",
	isLoading: false,
	disabled: false,
};

export default Button;
