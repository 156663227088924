/* App.js */

/** External Module Dependencies **/
import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";

import Footer from "common/elements/Footer";

import "../styles/style-bootstrap.css";
import "../styles/style-index.css";
import "../styles/style-app.css";

/** Global Module Dependencies **/
import routes from "routes";

const App = () => {
	const context = useWeb3React();

	useEffect(() => {
		/*window.onload = function () {
			// Create a new instance of the application
			const application = new Application();

			// Initialize the CircleContainer objects
			application.initializeCircleContainers();

			// Start the initial loop function for the first time
			application.loop();
		};*/
	}, []);

	return (
		<div className="app">
			<div className="main-content">
				<Routes>
					{routes.map((route, idx) => {
						return route.component ? <Route key={idx} path={route.path} exact={route.exact} name={route.name} element={<route.component context={context} />} />  : null;
					})}
				</Routes>
				<Footer />
			</div>
		</div>
	);
};

export default App;
