import React from "react";
import styled from "styled-components";

/** Global Module Dependencies **/
import Button from "common/elements/Button";
import Flex from "common/elements/Flex";
import Heading from "common/elements/Heading";
import { StyledIcon as ArrowBackIcon, StyledIcon as CloseIcon } from "common/elements/Icons";

const IconButton = styled(Button)`
	background: none !important;
	border: none !important;
	outline: none !important;
	box-shadow: none !important;	
`;

const ModalHeader = styled.div`
	display: flex;
	align-items: center;
	padding: 0 24px;
`;

const ModalHeading = styled(Heading)`
	color: #ffffff;
	font-family: inherit;
	font-size: 1.125rem!important;
  	margin: 1rem 0 0;
`;

const ModalTitle = styled(Flex)`
	align-items: center;
	flex: 1;
`;

const StyledModal = styled.div`
	background-image: linear-gradient(200.6deg, #025e7380 19.14%, #013440d9 154.68%);
	box-shadow: 0 20px 36px -8px rgba(14, 14, 44, 0.1), 0px 1px 1px rgba(0, 0, 0, 0.05);
	border: 1px solid ${({ theme }) => theme.colors.borderColor};
	border-radius: 1rem;
	width: 100%;
	z-index: ${({ theme }) => theme.zIndices.modal};
	overflow-y: auto;

	${({ theme }) => theme.mediaQueries.xs} {
		width: auto;
		min-width: 360px;
		max-width: 80vw;
	}
`;

const Modal = ({ title = undefined, onDismiss = undefined, onBack = undefined, children = undefined, hideCloseButton = false, bodyPadding = "24px" }) => (
	<StyledModal className="glass">
		<ModalHeader>
			<ModalTitle>
				{onBack && (
					<IconButton variant="text" onClick={onBack} area-label="go back" mr="8px">
						<ArrowBackIcon color="primary" />
					</IconButton>
				)}
				<ModalHeading>{title}</ModalHeading>
			</ModalTitle>
			{!hideCloseButton && (
				<IconButton variant="text" onClick={onDismiss} aria-label="Close the dialog">
					<CloseIcon color="primary" />
				</IconButton>
			)}
		</ModalHeader>
		<Flex flexDirection="column" p={bodyPadding}>
			{children}
		</Flex>
	</StyledModal>
);

export default Modal;
