/* index.js */

/** External Module Dependencies **/
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { Web3Provider } from "@ethersproject/providers";
import { Web3ReactProvider } from "@web3-react/core";
import { ThemeContextProvider } from "contexts/ThemeContext";

import "bootstrap/dist/css/bootstrap-reboot.css";
import "shards-ui/dist/css/shards.css";

/** Global Module Dependencies **/
import AppInitialState from "app-state/app-initial-state";
import App from "components/app/elements/app";
import { ModalProvider } from "common/elements/Modal";
import * as serviceWorker from "serviceWorker";
import configureStore from "store/store";

/** Internal Module Dependencies **/
import "./styles/index.css";

function getLibrary(provider, connector) {
	const library = new Web3Provider(provider);
	library.pollingInterval = 12000;

	return library;
}

ReactDOM.render(
	<Provider store={configureStore(AppInitialState)}>
		<ThemeContextProvider>
			<BrowserRouter>
				<ModalProvider>
					<Web3ReactProvider getLibrary={getLibrary}>
						<App />
					</Web3ReactProvider>
				</ModalProvider>
			</BrowserRouter>
		</ThemeContextProvider>
	</Provider>,
	document.getElementById("root")
);

serviceWorker.unregister();
